<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add New" }} Recurring Task
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="meta-form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Title *"
                v-model="fields.title"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('title')"
                :error-messages="errors['title']"
              ></v-text-field>
              <v-textarea
                label="Description"
                v-model="fields.description"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('description')"
                :error-messages="errors['description']"
              ></v-textarea>
              <v-select
                label="Task Type"
                v-model="fields.task_type"
                :items="task_types"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('task_type')"
                :error-messages="errors['task_type']"
                :hide-details="!errors.hasOwnProperty('task_type')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-switch
                label="Internal"
                v-model="fields.internal"
                inset
                :error="errors.hasOwnProperty('internal')"
                :error-messages="errors['internal']"
                :hide-details="!errors.hasOwnProperty('internal')"
              ></v-switch
            ></v-col>
            <v-col cols="8">
              <v-select
                v-if="fields.internal == false"
                label="Customer"
                v-model="fields.customer_id"
                :items="customers"
                item-value="id"
                item-text="name"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('customer_id')"
                :error-messages="errors['customer_id']"
                :hide-details="!errors.hasOwnProperty('customer_id')"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-select
                label="Assignee"
                v-model="fields.assignee"
                :items="users"
                item-value="id"
                item-text="full_name"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('assignee')"
                :error-messages="errors['assignee']"
              ></v-select>

              <v-select
                label="Task Type"
                v-model="fields.frequency"
                :items="frequencies"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('frequency')"
                :error-messages="errors['frequency']"
              ></v-select>

              <v-text-field
                v-if="fields.frequency == 'Monthly'"
                label="Frequency Number *"
                v-model="fields.frequency_number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('frequency_number')"
                :error-messages="errors['frequency_number']"
              ></v-text-field>

              <v-text-field
                v-if="fields.frequency == 'Monthly'"
                label="Due Date Number *"
                v-model="fields.due_date_number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('due_date_number')"
                :error-messages="errors['due_date_number']"
              ></v-text-field>

              <v-select
                v-if="fields.frequency == 'Weekly'"
                label="Frequency Number"
                v-model="fields.frequency_number"
                :items="frequency_week"
                item-text="label"
                item-value="value"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('frequency_number')"
                :error-messages="errors['frequency_number']"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="meta-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      task: {},
      fields: {
        description: null,
        title: null,
        task_type: "Contract",
        internal: false,
        assignee: null,
        frequency: null,
        frequency_number: null,
        due_date_number: null,
        customer_id: null,
      },
      task_types: ["Project", "Ad-hoc", "Unknown", "Contract"],
      frequencies: ["Monthly", "Weekly"],
      frequency_week: [
        { value: "1", label: "Monday" },
        { value: "2", label: "Tuesday" },
        { value: "3", label: "Wednesday" },
        { value: "4", label: "Thursday" },
        { value: "5", label: "Friday" },
        { value: "6", label: "Saturday" },
        { value: "7", label: "Sunday" },
      ],
      errors: {},
    };
  },

  methods: {
    open(task = null) {
      if (task !== null) {
        this.task = task;
        this.isEditing = true;
        this.fields.title = task.title;
        this.fields.description = task.description;
        this.fields.task_type = task.task_type;
        this.fields.customer_id = task.customer_id;
        this.fields.internal = task.internal;
        this.fields.assignee = task.assignee;
        this.fields.frequency = task.frequency;
        this.fields.due_date_number = task.due_date_number;
        this.fields.frequency_number = task.frequency_number.toString();
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.task = {};
      this.fields.title = null;
      this.fields.description = null;
      this.fields.task_type = null;
      this.fields.customer_id = null;
      this.fields.internal = false;
      this.fields.assignee = null;
      this.fields.frequency = null;
      this.fields.frequency_number = null;
      this.fields.due_date_number = null;
      this.errors = {};
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.taskId = this.task.id;
      }

      this.$store
        .dispatch("workload/saveTask", payload)
        .then(() => this.reset())
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
  computed: {
    customers() {
      return this.$store.state.customers["customers"];
    },

    users() {
      return this.$store.state.team["teamMembers"];
    },
  },
};
</script>
