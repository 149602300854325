<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Recurring Tasks</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search My Tasks"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.taskDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Recurring Task</span>
            </v-tooltip>

            <v-btn
              depressed
              light
              :to="{ name: 'module-workload-recurring-archived' }"
            >
              <v-icon left>mdi-archive</v-icon>View Archived
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="tasks"
        no-data-text="There are currently no tasks"
      >
        <template v-slot:item.frequency_day="{ item }">
          {{ item.frequency }} - {{ item.frequency_formatted }}
        </template>

        <template v-slot:item.customer="{ item }">
          <router-link
            v-if="item.customer"
            :to="{
              name: 'customer-profile-basic',
              params: { customerId: item.customer.id },
            }"
            >{{ item.customer.name }}</router-link
          >
          <div v-if="item.internal">Internal</div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                class="blue lighten-4 blue--text mr-2"
                v-on="on"
                @click="$refs.taskDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDelete(item)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
            <span>Archive Meta</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="deleteTask.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Task</v-card-title>
        <v-card-text
          >Are you sure you wish to archive Dyta
          {{ deleteTask.task.title }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteTask.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <task-dialog ref="taskDialog" />
  </div>
</template>

<script>
import TaskDialog from "./components/TaskDialog.vue";

export default {
  components: {
    TaskDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Workload",
          disabled: false,
          exact: true,
          to: {
            name: "module-workload-all",
            params: { id: this.$route.params.id },
          },
        },
      ],
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Customer", value: "customer" },
        { text: "Assigned", value: "assigned.full_name" },
        { text: "Frequency and Day", value: "frequency_day" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteTask: {
        dialog: false,
        task: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(task) {
      this.deleteTask.task = task;
      this.deleteTask.dialog = true;
    },

    resetDelete() {
      this.deleteTask.dialog = false;
      this.deleteTask.task = {};
      this.deleteTask.loading = false;
    },

    saveDelete() {
      this.deleteTask.loading = true;

      this.$store
        .dispatch("workload/archiveTask", {
          appId: this.$route.params.id,
          taskId: this.deleteTask.task.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteTask.loading = false;
        });
    },
  },

  computed: {
    tasks() {
      let tasks = this.$store.getters["workload/tasks"];

      if (this.searchTerm !== "") {
        tasks = tasks.filter((c) => {
          const title = c.title.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return title.includes(searchTerm);
        });
      }

      return tasks;
    },
  },
};
</script>
